<template>
  <div class="name_seach_box">
    <nav>
      <!-- <p><span></span><span>按姓氏首字母检索：</span></p> -->
      <p><span></span><span>{{ props.en?'Search by first letter of last name:':'按姓氏首字母检索：' }}</span></p>
       <section ref="section">
         <span v-for="(item,i) in xArr" :key="i" @click="xclick(item,i)">{{item}}</span>
       </section>
    </nav>
  </div>
</template>

<script setup>
import {ref,reactive,onMounted,watch,defineProps,defineEmits} from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import  * as axios from '@/api/API.js'
import base64_ from '@/hooks/useBase64text.js'
const route = useRoute()
const emits = defineEmits(['py_Change'])
const props = defineProps({
  en:Boolean
})
let search_py = ref("")
const section = ref(null)
const xArr = reactive(['#','A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'])
const xclick = (item,ii) => {
  for(let i = 0; i < section.value.children.length; i ++){
    section.value.children[i].style.backgroundColor = ''
    section.value.children[i].style.color = '#3e7ac2'
  }
  window.sessionStorage.setItem("ii",ii)
  section.value.children[ii].style.backgroundColor = '#003e8a'
  section.value.children[ii].style.color = '#fff'
  emits("py_Change",item=='#'?'':item)
}

onMounted(() => {
   domStyle()
   const iTwo = window.sessionStorage.getItem("ii")
   if(iTwo){
    for(let i = 0; i < section.value.children.length; i ++){
    section.value.children[i].style.backgroundColor = ''
    section.value.children[i].style.color = '#3e7ac2'
  }
    section.value.children[iTwo].style.backgroundColor = '#003e8a'
    section.value.children[iTwo].style.color = '#fff'
   }
})

 // 进页面就监听路由的变化，请求数据 和选中点进来的li元素  
  watch(() => route.params, () => {
    window.sessionStorage.removeItem("ii")
    if(!route.params.obj) return
    domStyle()
  }, {deep: true })

let domStyle = () => {
  for(let i = 0; i < section.value.children.length; i ++){
    section.value.children[i].style.backgroundColor = ''
    section.value.children[i].style.color = '#3e7ac2'
  }
  section.value.children[0].style.backgroundColor = '#003e8a'
  section.value.children[0].style.color = '#fff'
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.name_seach_box{
  width: 100%;
  height: 200/@maxw;
  background-color: #ccc;
  background-image: url("../assets/search_background.png");
  background-size: 100% 100%;
  color: rgb(183,163,147);
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: 20/@maxw;
  font-family: Source Han Sans CN-Medium;
 nav{
 width: 90%;
 height: 70%;
 margin: 0 auto;  
  >p{
     >span:nth-child(1){
      display: inline-block;
      width: 3/@maxw;
      height: 15/@maxw;
      margin:0 10/@maxw 0 0;
      background-color: rgb(183,163,147);
    }
  }
  >section{
    display: flex;
    justify-content: space-between;
    color: #3e7ac2;
    cursor: pointer;
    span{
      position: relative;
      z-index: 999999!important;
      transition: ease .3s;
      width: 50/@maxw;
      height: 50/@maxw;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span:hover{
      transform: scale(1.2) !important;
      color: #fff !important;
      background-color: #003e8a !important;
    }
  }
 }
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .name_seach_box{
     height: 300/@maxw_;
     font-size: 20/@maxw_;
     >nav{
      p{
        margin-top: -8/@maxw_;
        font-size: 40/@maxw_;
      }
      >section{
          margin-top: -20/@maxw_;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        span{
          width: 50/@maxw_;
          height: 50/@maxw_;
          font-size: 35/@maxw_;
        }
      }
     }
  }
}
</style>