<template>
  <div class="zrjs_box" ref="zrjs_box">
    <h1>{{h1}}</h1>
    <nav>
      <p>{{thach_list.Departmen}}</p>
      <nameSeach :poId="poId_" @py_Change="py_Change_"/>
        <!-- 老师区域 -->
      <div class="nama_tach">
        <span>按姓氏首字母排序：</span>
        <div class="card_name">
          <nav v-for="(item,i) in thach_list.list" :key="i" @click="push_articleTime(item)">
            <img :src="item.thumb?item.thumb.indexOf('http')!=-1?item.thumb:baseUrl+item.thumb:'https://s1.ax1x.com/2022/08/16/vw6g10.png'" alt="">
            <section>
               <p>{{item.title?item.title:'暂无'}}</p>
               <div v-for="itemTwo in item.tags&&item.tags.slice(0,4)" :key="itemTwo.id">{{itemTwo}}</div>
               <footer>
                <span>更多详情</span>
                <img src="@/assets/zrjsicon.png" alt="">
               </footer>
            </section>
          </nav>
        </div>
      </div>
      <!-- 底部分页 -->
      <div class="pagination">
        <Pagination :total="thach_list.total" :page="thach_list.page" :limit="thach_list.limit" @pageChange="pageChanges"/>
      </div>
    </nav>
  </div>
</template>

<script setup>
import { get_article_list,getHttp } from '@/api/API.js'
import nameSeach from '@/components/name_seach.vue'
import Pagination from '@/components/Pagination.vue'
import { useRouter,useRoute, } from 'vue-router'
import {ref,onMounted,reactive,watch,onUnmounted} from 'vue'
import base64_ from '@/hooks/useBase64text.js'
const baseUrl = process.env.VUE_APP_BASE_URL + '/'
const zrjs_box = ref(null)
const route = useRoute()
const router = useRouter()
const h1 = ref("")
let routeData = JSON.parse(base64_.decode(route.params.obj))
const thach_list = reactive({
  list:[],
  Departmen:'',
  page:1,
  limit:10,
  total:0,
  search_py:''
})
// 根据路由传的id获取文章列表详情
let get_Navaeticlexq = async (obj) => {
  const {data:res} = await get_article_list(obj)
  if(res.code == 200){
    getDepartment()
    thach_list.list = res.data.data
    thach_list.total = res.data.total
    setTimeout(() => {
      if(zrjs_box.value){
        zrjs_box.value.style.opacity = '1'
        zrjs_box.value.style.transform = 'translateY(0)'
      }
    },500)
  }
}
// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
  const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
  if(sessStor){
    thach_list.page = sessStor
    get_Navaeticlexq({ type_id: routeData.article_id,page:thach_list.page,limit:thach_list.limit,search_py:thach_list.search_py})
  }else{
    thach_list.page = 1
    get_Navaeticlexq({ type_id: routeData.article_id,page:thach_list.page,limit:thach_list.limit,search_py:thach_list.search_py})
  } 
}

// 监听动画执行完毕就显示出来
onMounted(() => {
   zrjs_box.value.addEventListener("animationend",() => {
    zrjs_box.value.children[0].style.opacity = '1'
    zrjs_box.value.children[1].style.opacity = '1'
  })
  // 进页面就监听路由的变化，请求数据 和选中点进来的li元素  
  watch(() => route.params, () => {
    route.params.obj?routeData = JSON.parse(base64_.decode(route.params.obj)):''
    window.sessionStorage.setItem('page',1)
    window.sessionStorage.removeItem("search_py")
    thach_list.search_py = ''
    adimations()
  }, {deep: true })
  adimations()
})
// 通过分页子组件的自定义事件来改变page
const pageChanges = (e) => {
  thach_list.page = e
  window.sessionStorage.setItem('page',e)
  editFrompage()
}

const py_Change_ = async (e) => {
  window.sessionStorage.setItem("search_py",e)
  window.sessionStorage.setItem('page',1)
  thach_list.search_py = e
  thach_list.page = 1
  editFrompage()
}
// 拼音关键字获取文章列表
const getSearchpy = async () => {
  const { data: res } = await getHttp('/api/article', { search_py: thach_list.search_py, type_id: routeData.article_id,page:thach_list.page})
  if(res.code == 200){
    thach_list.list = res.data.data
    thach_list.total = res.data.total
  }
}

import { ElNotification  } from 'element-plus'
const push_articleTime = (row) => {
  if(row.jump_url){
    window.open(row.jump_url)
    return
  }else{
    ElNotification({
    title: '警告',
    message: '该教师没有链接。',
    type: 'warning',
    zIndex:999999
  })
  }
// router.push({
//     name:"Article_xq_time",
//     params:{obj:base64_.encode(JSON.stringify({
//       title1:row.type_name,
//       article_wz_id:row.id,
//       id:35,
//       index:routeData.index
//     }))}
  // })
}
// 初次进入页面加载数据和做动画
const adimations = () => {
  // 如果本地缓存有拼音字母就携带拼音请求数据
  const serPy = window.sessionStorage.getItem('search_py')
  if(serPy){
  thach_list.search_py = serPy
  editFrompage()
  }else{
  editFrompage()
  }
  zrjs_box.value.style.opacity = '0'
  zrjs_box.value.style.transform = 'translateY(500px)'
  document.documentElement.scrollTop = 0
  // thach_list.page = 1
  h1.value = routeData.title3?routeData.title3:routeData.title2?routeData.title2:routeData.title1?routeData.title1:''
}
// 获取系介绍
const getDepartment = async () => {
  const { data: res } = await getHttp('/api/getTypeDesc', {  type_id: routeData.article_id})
  if(res.code == 200){
    thach_list.Departmen = res.data.description
  }
}

onUnmounted(() => {
  if(!route.params.obj) return
  if(!JSON.parse(base64_.decode(route.params.obj)).article_wz_id){
     window.sessionStorage.setItem('page',1)
  }
})
</script>
<style lang="less" scoped>
@maxw:128rem;
.zrjs_box{
  width: 100%;
  font-size: 15/@maxw;
  margin: 0 30/@maxw;
  transition: ease .5s;
  >h1{
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 10/@maxw 0;
    animation: pageYs .8s ease;
    opacity: 0;
  }
  >nav{
    animation: pageYs .8s ease .5s;
    opacity: 0;
    width: 100%;
    >P{
      margin: 40/@maxw 0;
      font-size: 24/@maxw;
      // font-family: Source Han Sans CN-Medium;
      font-family: Source Han Sans CN-Medium;
      font-weight: 500;
      color: #5A5A5A;
    }
    .nama_tach{
      width: 100%;
      margin: 30/@maxw 0 0 0;
      >span{
        color: rgb(183,163,147);
        font-size: 20/@maxw;
        font-family: Source Han Sans CN-Medium;
      }
      .card_name{
        margin: 10/@maxw 0 0 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        font-family: Source Han Sans CN-Medium;
        >nav{
          display: flex;
          width: 525/@maxw;
          height: 220/@maxw;
          background-color: #fff;
          margin: 0 0 36/@maxw 0;
          transition: ease .3s;
          cursor: pointer;
          >img{
            width: 201/@maxw;
            height: 100%;
            vertical-align: top;
            // object-fit: cover;
          }
          >section{
            position: relative;
            box-sizing: border-box;
            width: 310/@maxw;
            height: 100%;
            background-color: #e8e8e8;
            margin: 0 0 0 15/@maxw;
            padding: 0 0 0 20/@maxw;
            color: #5A5A5A;
            >p{
              font-size: 18/@maxw;
              padding: 8/@maxw 0;
              margin: 0;
              color: #3e7ac2;
              border-bottom: 2/@maxw solid rgb(183,163,147);
            }
            div{
              padding-right: 10/@maxw;
            }
            >div:nth-of-type(1){
              // margin-top: 30/@maxw;
              margin-top: 25/@maxw;
            }
            >footer{
              position: absolute;
              // bottom: 16/@maxw;
              bottom: 10/@maxw;
              right: 16/@maxw;
              height: 37/@maxw;
              float: right;
              display: flex;
              font-size: 13/@maxw;
              justify-content: center;
              align-items: center;
              color: #B19A86;
              >img{
                margin-left: 7/@maxw;
                vertical-align: middle;
              }
            }
          }
        }
        >nav:nth-child(2n){
          margin-left: 50/@maxw;
        }
        >nav:hover {
          box-shadow: 0 0 20/@maxw rgba(90, 90, 90, 0.291);
          footer{
              text-decoration: underline;
          }
        }
      }
      
    }
  }
  .pagination{  
  display: flex;
  justify-content: center;
  margin: 13% 0 0 0; 
  }

}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .zrjs_box{
    >h1{
      font-size: 35/@maxw_ !important;
    }
    >nav{
      >p{
        font-size: 28/@maxw_ !important;
      }
    }
    .nama_tach{
      >span{
        font-size: 30/@maxw_ !important;
      }
      .card_name{
        nav{
          width: 800/@maxw_ !important;
          // height: 165/@maxw_ !important;
          height: 36vw !important;
          margin: 0 0 15/@maxw_ 0 !important;
          >img{
            width: 250/@maxw_ !important;
            height: 270/@maxw_ !important;
          }
          >section{
            flex: 1;
            height: 270/@maxw_ !important;
            >p{
              font-size: 30/@maxw_ !important;  
            }
            >footer{
              width: 173/@maxw_ !important;
              height: 47/@maxw_ !important;
              bottom: 21/@maxw_ !important;
              font-size: 25/@maxw_ !important;
            }
            >div:nth-of-type(1){
              margin-top: 30/@maxw_ !important;
              font-size: 25/@maxw_ !important;
            }
            >div:nth-of-type(2){
              font-size: 25/@maxw_ !important;
            }
          }
        }
      }
    }
  }
}
</style>